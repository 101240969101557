import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderTwo from '../Components/Header/HeaderTwo'
import BekoFoto18 from '../assets/img/bekofoto18.jpg';
import PageBanner from '../Components/PageBanner';
import ServicesOne from "../Components/Services/ServicesOne";
import ServicesCta from "../Components/Services/ServicesCta";
import BestFeatures from "../Components/Features/BestFeatures";

const ServicePage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner title='ÜRÜNLERİMİZ' bannerBg={BekoFoto18} currentPage='ÜRÜNLER' />
      <ServicesOne/>
      <ServicesCta/>
      <BestFeatures/>
      <FooterTwo/>
    </>
  )
}

export default ServicePage