import AboutTwo from "../Components/About/AboutTwo";
import ServicesTwo from "../Components/Services/ServicesTwo";
import FooterTwo from "../Components/Footer/FooterTwo";
import WhyUs from "../Components/About/WhyUs";
import HeaderTwo from "../Components/Header/HeaderTwo";
import HeroTwo from "../Components/Hero/HeroTwo";

const HomeTwo = () => {
  return (
    <>
      <HeaderTwo/>
      <HeroTwo/>
      <AboutTwo/>
      <ServicesTwo/>
      <WhyUs/>
      <FooterTwo/>
    </>
  )
}

export default HomeTwo;