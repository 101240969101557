import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/beko1.png';
import img2 from '../../assets/img/beko2.png';
import img3 from '../../assets/img/beko3.png';
import img4 from '../../assets/img/beko4.png';
import img5 from '../../assets/img/beko5.png';
import img6 from '../../assets/img/beko6.png';
import img7 from '../../assets/img/beko7.png';

const servicesTwoData = [
    {
        id: uuidv4(),
        img: img1,
        title: 'BEYAZ EŞYA',
        desc: 'Buzdolabı, çamaşır makinesi, bulaşık makinesi, kurutma makinesi, kurutmalı çamaşır makinesi, derin dondurucu, fırın, mikrodalga fırın, set üstü ocak, su sebili',
    },

    {
        id: uuidv4(),
        img: img2,
        title: 'ELEKTRONİK',
        desc: 'Bilgisayar, cep telefonu, cep telefonu aksesuarları, giyilebilir teknoloji, hobi oyun, ses ve görüntü sistemleri, spor ve outdoor, telefon, tv ve tv ünitesi',
    },

    {
        id: uuidv4(),
        img: img3,
        title: 'ANKASTRE',
        desc: 'Ankastre aspiratörler, ankastre bulaşık makineleri, ankastre buzdolabı, ankastre davlumbazlar, ankastre ocaklar, ankastre fırınlar, ankastre mikrodalgalar',
    },
    
    {
      id: uuidv4(),
      img: img4,
      title: 'İÇECEK HAZIRLAMA',
      desc: 'Çay makinesi, kahve makinesi,filtre kahve makinesi, narenciye sıkacağı, katı meyve sıkacağı, semaver, espresso makinesi',
    },

    {
      id: uuidv4(),
      img: img5,
      title: 'ISITMA - SOĞUTMA',
      desc: 'Elektrikli ısıtıcı, hava soğutucu, iç hava kalitesi, klima, kombi, termosifon, vantilatör, şofben',
    },

    {
      id: uuidv4(),
      img: img6,
      title: 'CEP TELEFONU',
      desc: 'İPhone, Samsung, TCL, Realme, Lenova, ALCATEL, Poco, Oppo, Xiaomi, Grundig, REEDER',
    },

    {
      id: uuidv4(),
      img: img7,
      title: 'BİGİSAYAR',
      desc: 'Lenova, MacBook, İPad, Samsung, Alcatel, Razer, APPLE, HUAWEI',
    },
]

export default servicesTwoData;