import React from 'react'
import { Link } from 'react-router-dom'

const MobileMenu = ({mobileMenu, handleMobileMenu}) => {
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
        <button onClick={handleMobileMenu} type="button" className="close-nav">
            <i className="fal fa-times-circle"></i>
        </button>
        <nav className="sidebar-nav">
            <ul className="metismenu" id="mobile-menu">
                <li><a className="has-arrow" href="/">Anasayfa</a>
                </li>
                <li><Link to="/about">HAKKIMIZDA</Link></li>
                <li><Link to="/services">ÜRÜNLERİMİZ</Link></li>
                <li><Link to="/contact">İLETİŞİM</Link></li>
            </ul>
        </nav>

        <div className="action-bar">
            <a href="mailto:yenimevsimtic@biri.com.tr"><i className="fal fa-envelope"></i>yenimevsimtic@biri.com.tr</a>
            <a href="tel:0242 334 27 91"><i className="fal fa-phone"></i>0242 334 27 91</a>
            <Link to='/contact' className="d-btn theme-btn">İletişim</Link>
        </div>
    </div>    
     
  )
}

export default MobileMenu