import React from 'react'
import BekoFoto24 from '../../assets/img/bekofoto24.jpg';

const BestFeatures = () => {
  return (
    <section className="best-featured-wrapper section-padding pb-5 mb-5">
        <div className="container">
            <div className="row mtm-40 align-items-center">
            <div className="col-lg-4 col-md-6 col-12">
                <div className="single-best-featured right">
                <div className="icon">
                    <i className="flaticon-flow-chart" />
                </div>
                <div className="content-text">
                    <h5>BERABER SEÇELİM</h5>
                    <p>Hangi kategoride ürün arıyorsunuz? Şubemize gelin beraber seçelim.</p>
                </div>
                </div>
                <div className="single-best-featured right">
                <div className="icon">
                    <i className="flaticon-writing" />
                </div>
                <div className="content-text">
                    <h5>HARİKA RENKLER, YARATICI ÇİZİMLER</h5>
                    <p>Benzersiz ürün çeşitliliğimizle ve kalitemizle sizleri tanıştırmak için bayimize bekliyoruz.</p>
                </div>
                </div>
                <div className="single-best-featured right">
                <div className="icon">
                    <i className="flaticon-interior-design" />
                </div>
                <div className="content-text">
                    <h5>7/24 HİZMET</h5>
                    <p>Şubelerimiz 7/24 sizler için hizmet vermektedir.Ücretsiz Montaj. Yaygın Hizmet Ağı. Çağrı Merkezi.</p>
                </div>
                </div>
            </div>
            <div className="col-lg-4 p-md-0 mt-5 mb-5 mt-md-0 mb-md-0 col-md-6 col-12">
                <div className="center-img text-center mbm-50">
                <img src={BekoFoto24} alt="beko" />
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
                <div className="single-best-featured">
                <div className="icon">
                    <i className="flaticon-secure-shield" />
                </div>
                <div className="content-text">
                    <h5>GÜVENİLİR ÇÖZÜM</h5>
                    <p>Ürünler, Garanti, ve Servislerimizle ilgili tüm bilgilere tek noktadan ulaşabilirsiniz.</p>
                </div>
                </div>
                <div className="single-best-featured">
                <div className="icon">
                    <i className="flaticon-team" />
                </div>
                <div className="content-text">
                    <h5>YETENEKLİ EKİP</h5>
                    <p>Yılların vermiş olduğu deneyim ile cana yakın ve uzman ekibimiz ile hizmetinizdeyiz.</p>
                </div>
                </div>
                <div className="single-best-featured">
                <div className="icon">
                    <i className="flaticon-profits" />
                </div>
                <div className="content-text">
                    <h5>UYGUN FİYATLANDIRMA</h5>
                    <p>Değişimin Heyecanı ile Evinizde Yeni Bir Başlangıç Yapın, Beko Beyaz Eşyaları Keşfedin! Aradığınız Beyaz Eşyalar Mevsim Ticaret te Sizi Bekliyor. </p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>

  )
}

export default BestFeatures