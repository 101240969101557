import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderTwo from '../Components/Header/HeaderTwo'
import BekoFoto7 from '../assets/img/bekofoto7.jfif';
import PageBanner from '../Components/PageBanner';
import AboutOne from '../Components/About/AboutOne';

const AboutPage = () => {
  return (
    <>
      <HeaderTwo />
      <PageBanner title='HAKKIMIZDA' bannerBg={BekoFoto7} currentPage='HAKKIMIZDA' />
      <AboutOne pt />
      <FooterTwo/>
    </>
  )
}

export default AboutPage