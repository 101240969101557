import {v4 as uuidv4} from 'uuid';
import BekoFoto19 from '../../assets/img/bekofoto19.webp';
import BekoFoto20 from '../../assets/img/bekofoto20.webp';
import BekoFoto21 from '../../assets/img/bekofoto21.jpg';
import BekoFoto22 from '../../assets/img/bekofoto22.jpg';
import BekoFoto23 from '../../assets/img/bekofoto23.jpg';

const servicesOneData = [
    {
        id: uuidv4(),
        bg: BekoFoto19,
        title: 'BEYAZ EŞYA MODELLERİ',
        desc: 'Beyaz eşya modelleri marka, model, tasarım, renk, kullanım şekli, çalışma prensibi ve teknik özelliklerine göre çeşitlilik gösterir.',
    },

    {
        id: uuidv4(),
        bg: BekoFoto20,
        title: 'BEKO KLİMA VE KOMBİ MODELLERİ',
        desc: 'Beko ya gelin, uygun fiyatlarla kombi ve klima modellerini keşfedin.Bu Fırsatı Kaçırmayın!',
    },

    {
        id: uuidv4(),
        bg: BekoFoto21,
        title: 'ELEKTRONİK MODELLER',
        desc: 'Elektronik fiyatları ve size en uygun Elektronik modelleri için şubelerimizi ziyaret edin...',
    },

    {
        id: uuidv4(),
        bg: BekoFoto22,
        title: 'ANKASTRE ÜRÜNLER',
        desc: 'Son Teknoloji Beyaz Eşya ve Ankastre Modelleri Şık Tasarımlarıyla Beko da.Hemen Ürünleri İncelemeye Başlayın.',
    },

    {
        id: uuidv4(),
        bg: BekoFoto23,
        title: 'İÇECEK HAZIRLAMA MODELLERİ',
        desc: 'Size en uygun İçecek Hazırlama modelleri için Beko yetkili bayimizi ziyaret edin.',
    },
    
]

export default servicesOneData;