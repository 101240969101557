const ContactPageContents = () => {

  return (
    <div className="contact-us-wrapper section-padding">
      <div className="container">
      <h2>İLETİŞİM BİLGİLERİ</h2>
        <div className="row eq-height">
          <div className="col-lg-6 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h3>MERKEZ</h3>
                <div className="single-info">
                  <div className="icon">
                      <i className="flaticon-email" />
                  </div>
                  <div className="text">
                      <span>Email</span>
                      <h6>yenimevsimtic@biri.com.tr</h6>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>Bizi Arayın</span>
                    <h6>0242 334 27 91</h6>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-pin" />
                  </div>
                  <div className="text">
                    <span>Adres</span>
                    <h6>ÖZGÜRLÜK MH. M.AKİF CAD. <br/> NO:21/A KEPEZ, ANTALYA</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12"> 
            <div>
              <iframe
                className="map_section"
                title="template google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.1430887446195!2d30.67954211556191!3d36.91084307992583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c38fc45ce8e555%3A0x4461a502441bad2f!2sMevsim%20Ticaret%20Beko%20Bayii!5e0!3m2!1str!2str!4v1674115746062!5m2!1str!2str"
                allowFullScreen
              ></iframe>
            </div>                                                 
          </div> 
        </div>

        <div className="row eq-height">
          <div className="col-lg-6 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h3>ŞUBE</h3>
                <div className="single-info">
                  <div className="icon">
                      <i className="flaticon-email" />
                  </div>
                  <div className="text">
                      <span>Email</span>
                      <h6>yenimevsimtic@biri.com.tr</h6>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>Bizi Arayın</span>
                    <h6>0242 343 29 89</h6>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-pin" />
                  </div>
                  <div className="text">
                    <span>Adres</span>
                    <h6>ZAFER MH. MİTHATPAŞA CAD. <br/> NO:78/A KEPEZ, ANTALYA</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12"> 
            <div>
              <iframe
                className="map_section"
                title="template google map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1896.7751520892298!2d30.686028213222656!3d36.91467439522748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c38fc04039fc4d%3A0x46185773e74e91f2!2sZafer%2C%20Mithat%20Pa%C5%9Fa%20Cd.%20No%3A78%2C%2007025%20Kepez%2FAntalya!5e0!3m2!1str!2str!4v1674477474166!5m2!1str!2str"
                allowFullScreen
              ></iframe>
            </div>                                                 
          </div> 
        </div>
      </div>
    </div>

  )
}

export default ContactPageContents;