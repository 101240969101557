import React from 'react'
import BekoFoto17 from '../../assets/img/home2/bekofoto17.jpg';

const WhyUs = () => {
  return (
    <section className="why-choice-us section-padding m-5">
        <div className="why-choice-bg bg-cover d-none d-xl-block" style={{backgroundImage: `url(${BekoFoto17})`}}></div>
        <div className="container">
            <div className="row">
                <div className="offset-xl-6 offset-lg-4 col-lg-8 col-xl-6 col-12">
                    <div className="block-contents">
                        <div className="section-title">
                            <span>NEDEN BİZİ SEÇMELİSİNİZ</span>
                            <h2>İNSANLAR KALİTE İÇİN BİZİ SEÇİYOR</h2>
                        </div>
                    </div>
                    <div className="single-icon-circle-item">
                        <div className="icon">
                            <i className="flaticon-cpu"></i>
                        </div>
                        <div className="contents">
                            <h3>İLERİ TEKNOLOJİ</h3>
                            <p>
                              Geleceğin günümüzden daha ileri yönde şekillenmesi için her bireyin katkı sağladığına ve bizim görevimizin bu süreçte onların yanında olmak olduğuna inanıyoruz. 
                            </p>
                        </div>
                    </div>
                    <div className="single-icon-circle-item">
                        <div className="icon">
                            <i className="flaticon-secure-shield"></i>
                        </div>
                        <div className="contents">
                            <h3>GÜVENİLİR</h3>
                            <p>Satın alacağınız veya aldığınız tüm Beko markaları *ürünlerin teslimatı evinize kadar **yetkili servislerimiz tarafından gönderilmekte ve açılışı yapılmaktadır.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhyUs