import React from 'react'
import { Link } from 'react-router-dom';
import BekoImage4 from '../../assets/img/home2/bekofoto4.jpg';

const AboutTwo = () => {
  return (
    <section className="about-section section-padding">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-6 col-12 pe-xl-0">
                    <div className="about-cover-bg bg-cover me-xl-5" style={{backgroundImage: `url(${BekoImage4})`}}>
                    </div>
                </div>
                <div className="col-xl-6 mt-5 mt-lg-0 col-12">
                    <div className="block-contents">
                        <div className="section-title">
                            <span>ŞİRKET HAKKINDA</span>
                            <h2>VİZYONUMUZ</h2>
                        </div>
                        <blockquote>
                          Kendimizi yenileyerek ve sektörümüzü geliştirerek, dijitalleşen hanelerin ve şirketlerin güvenilir çözüm ortağı olmak
                        </blockquote>
                    </div>

                    <p>   
                      140’dan fazla ülkede faaliyet gösteren Beko, inovatif ürünleri ve kusursuz hizmet anlayışıyla 1955 yılından bu yana tüketicilerinin
                      hayatlarına konfor katmaktadır. Avrupa’da solo beyaz eşya pazarının, İngiltere ve Polonya’da ise toplam beyaz eşya pazarının lideri
                      olan Beko, Türkiye'de de solo ve ankastre beyaz eşya, küçük ev aletleri, iklimlendirme ve elektronik ürünleri ile hayatı kolaylaştıran
                      global bir markadır.
                    </p>

                    <Link to="/about" className="theme-btn mt-35">daha fazlası</Link>
                </div>
            </div>
        </div>
        <h2 className="section-rotate-title d-none d-xxl-block">ŞİRKET</h2>
    </section>
  )
}

export default AboutTwo