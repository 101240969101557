import React from 'react'

const HeaderTwoTop = () => {
  return (
    <div className="header-top-bar text-white d-none d-sm-block">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9 col-sm-9">
            <ul className="top-left-content">
              <li><i className="flaticon-paper-plane"></i> yenimevsimtic@biri.com.tr</li>
              <li><i className="flaticon-map"></i>Özgürlük Mh. M.Akif Cad. No:21/A Kepez/ANTALYA</li>
            </ul>
          </div>
          <div className="col-lg-2 col-sm-3 text-end">
            <div className="top-social-icons">
              <a href="https://tr-tr.facebook.com/BekoMevsimTicaret/"><i className="fab fa-facebook-f"></i></a>
              <a href="#"><i className="fab fa-twitter"></i></a>
              <a href="#"><i className="fab fa-instagram"></i></a>
              <a href="#"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderTwoTop