import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderTwo from '../Components/Header/HeaderTwo'
import BekoFoto5 from '../assets/img/bekofot5.jpeg';
import PageBanner from '../Components/PageBanner';
import ContactPageContents from "../Components/Contact/ContactPageContents";

const ContactPage = () => {
  return (
    <>
      <HeaderTwo/>
      <PageBanner title='İLETİŞİM' bannerBg={BekoFoto5} currentPage='İLETİŞİM' />
      <ContactPageContents/>
      <FooterTwo/>
    </>
  )
}

export default ContactPage;
