import BekoFoto16 from '../../assets/img/bekofoto16.jpg';
import 'react-modal-video/scss/modal-video.scss';

const AboutOne = ({pt}) => {
    
  return (
    <>
      <section className={pt ? 'about-section section-padding' : 'about-section section-padding pt-0'}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="img-block-pop-video">
                <img src={BekoFoto16} alt="busico" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 col-12">
              <div className="block-contents">
                <div className="section-title">
                  <span>ŞİRKET HAKKINDA</span>
                  <h2>BEKO YETKİLİ SATICI: BEKO EV ALETLERİNİZ İÇİN HİZMET SAĞLAYICINIZ</h2>
                </div>
                <p>
                  “En değerli varlığımız; Müşterimizdir” Felsefesi ile yola çıkan firmamız, müşteri memnuniyeti ön planda tutarak en iyi hizmeti vermeye çalışmaktadır. 
                  Dileyen müşterilerimiz firmamızın showroom'unu gezerek satın almayı düşündükleri ürünü inceleyerek de satın alabilirler.
                  İlginiz için çok teşekkür ederiz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutOne;